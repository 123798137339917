import React from 'react';
import Header from './components/Header';
import Features from './components/Features';
import AIComponent from './components/Intro';

import './App.css';

function App() {
  return (
    <div className="App">
      <AIComponent />
    </div>
  );
}

export default App;