import React from 'react';
import './Intro.css'; // Import the CSS file for styling
import logo from './yaraf.svg';

const AIComponent = () => {
  return (
    <div className="ai-component">
      <div className="overlay">
        <header className="centered-content">
          <img alt='Yaraf Digital' width='80px' src={logo}></img>
          <h1>Yaraf Digital</h1>
          <p>Your Partner in Digital Innovation</p>
        </header>
      </div>
    </div>
  );
};

export default AIComponent;